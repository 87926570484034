import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../../interfaces/montantControleCoherence';
import FamilleControleCoherence from '../../../enums/familleControleCoherence';
import StatutControleCoherence from '../../../enums/statutControleCoherence';

class EquilibreReel extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.EQUILIBRE_BUDGETAIRE;
  }

  getExplicatif(): string {
    const annuite = formatMontant(this.montants.annuite, 2, true);
    const ressources = formatMontant(this.montants.ressources, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Le remboursement de l’annuité en capital de la dette (${annuite}) n'est pas couvert par les ressources propres de la collectivité (${ressources}).`;
      case StatutControleCoherence.OK:
        return `Le remboursement de l’annuité en capital de la dette (${annuite}) est couvert par les ressources propres de la collectivité (${ressources}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Non-respect de l'équilibre réel";
      case StatutControleCoherence.OK:
        return "Respect de l'équilibre réel";
      default:
        return '';
    }
  }

  getStatut(): number {
    const { annuite, ressources } = this.montants;

    if (ressources >= annuite) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreReel;
