import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';

import type MontantImputation from '../interfaces/montantImputation';

const url = '/api/finances/simulation/montantSimulation';

export const createMontant = (data: Partial<MontantImputation>) : Promise<MontantImputation> => request.post(url, data);

export const deleteMontant = (identifiantMontant: number) => request.delete(`${url}/${identifiantMontant}`);

export const getMontants = (
  identifiantImputationSimulation: number,
): Promise<TemplateRequestGet<MontantImputation>> => request.get(
  `${url}?all=true&withEtiquettes=true&identifiantImputationSimulation=${identifiantImputationSimulation}`,
);

export const updateMontant = (
  identifiantMontant: number,
  data: Partial<MontantImputation>,
) : Promise<MontantImputation> => request.put(`${url}/${identifiantMontant}`, data);

export const upsertMontant = (data: any) => request.put(`${url}/upsertByImputation`, data);

export const upsertMontants = (
  identifiantSimulation: number,
  montants: any,
) => request.put<unknown, unknown>(url, { montants, identifiantSimulation });
