import type React from 'react';
import Simulation from '../interfaces/simulation';
import TypeBudget from '../enums/typeBudget';
import TypeReport from '../enums/typeReport';
import type BaseFeuilleSaisie from '../../FeuilleSaisie/classes/FeuilleSaisie/BaseFeuilleSaisie';
import FeuilleSaisieCode from '../../FeuilleSaisie/constants/feuilleSaisieCode';
import type PanelsData from '../interfaces/panelsData';
import type { TreeRow } from '../../FeuilleSaisie/interfaces/treeRow';

const handleChangeImputation = (
  panelDataChange: PanelsData,
  simulation: Simulation,
  setSimulation: React.Dispatch<React.SetStateAction<Simulation>>,
) => {
  const panelData : PanelsData = {
    prop: {},
    demande: {},
    vote: {},
    exercicePrecedent: {},
    exercicePrecedent2: {},
    ca: {},
  };
  Object.keys(simulation.panelData).forEach((typePeriode) => {
    Object.keys(simulation.panelData[typePeriode]).forEach((key) => {
      if (typeof simulation.panelData[typePeriode][key] === 'object'
        && simulation.panelData[typePeriode][key] !== null) {
        // on ne met pas a jour les annees precedentes
        panelData[typePeriode][key] = simulation.panelData[typePeriode][key];
      } else {
        // eslint-disable-next-line max-len
        panelData[typePeriode][key] = (simulation.panelData[typePeriode][key] ?? 0) + (panelDataChange?.[typePeriode]?.[key] ?? 0);
      }
    });
  });

  setSimulation((s) => ({
    ...s,
    panelData,
  }));
};

export const handleChangeMontantToSimulation = (
  differencesMontants: any,
  imputation: TreeRow,
  simulation: Simulation,
  setSimulation: React.Dispatch<React.SetStateAction<Simulation>>,
) => {
  const panelDataChanges : PanelsData = { prop: {}, demande: {}, vote: {} };

  Object.keys(differencesMontants).forEach((typePeriode) => {
    if (imputation.imp_sens === 'D') {
      if (imputation.cha_section === 'I') {
        panelDataChanges[typePeriode].depenseInvestissement = differencesMontants[typePeriode];
      } else {
        panelDataChanges[typePeriode].depenseFonctionnement = differencesMontants[typePeriode];
        if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
          && imputation.cha_nat_bud === 'R'
          && !imputation.art_code?.startsWith('002')) {
          panelDataChanges[typePeriode].depenseReelFonctionnement = differencesMontants[typePeriode];

          if (!imputation.art_code?.startsWith('002')) {
            panelDataChanges[typePeriode].epargneBrute = -differencesMontants[typePeriode];
          }
        }
      }

      if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF && imputation.cha_code === '012') {
        panelDataChanges[typePeriode].montantChargePersonnel = differencesMontants[typePeriode];
      }

      if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
        && imputation.cha_nat_bud === 'R'
        && imputation.art_code?.startsWith('1641')) {
        panelDataChanges[typePeriode].remboursementCapitalDette = differencesMontants[typePeriode];
      }
    } else if (imputation?.imp_sens === 'R') {
      if (imputation?.cha_section === 'I') {
        panelDataChanges[typePeriode].recetteInvestissement = differencesMontants[typePeriode];
      } else {
        panelDataChanges[typePeriode].recetteFonctionnement = differencesMontants[typePeriode];

        if (imputation.cha_nat_bud === 'R' && !imputation.art_code?.startsWith('002')) {
          panelDataChanges[typePeriode].recetteReelFonctionnement = differencesMontants[typePeriode];
        }

        if (!imputation.art_code?.startsWith('002')
        && !imputation.art_code?.startsWith('775')
        && !simulation?.transfertCpta
        && simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
        && imputation.cha_nat_bud === 'R') {
          panelDataChanges[typePeriode].epargneBrute = differencesMontants[typePeriode];
        }
      }
    }
  });

  handleChangeImputation(panelDataChanges, simulation, setSimulation);
};

export const shouldAddReport = (simulation: Simulation, feuille: BaseFeuilleSaisie) => {
  let reportTypeBudget = null;
  switch (simulation.exercice.reportBpBs) {
    case TypeReport.BUDGET_PRIMITIF:
      reportTypeBudget = TypeBudget.BUDGET_PRIMITIF;
      break;
    case TypeReport.BUDGET_SUPPLEMENTAIRE:
      reportTypeBudget = TypeBudget.BUDGET_SUPPLEMENTAIRE;
      break;
    case TypeReport.DECISION_MODIFICATIVE:
      reportTypeBudget = TypeBudget.DECISION_MODIFICATIVE;
      break;
    default:
  }

  return feuille.code !== FeuilleSaisieCode.FONCTIONNEMENT
    ? simulation.budget.type === reportTypeBudget
    : (simulation.budget.type === reportTypeBudget && simulation?.exercice?.budReportFonc);
};
