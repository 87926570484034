import React from 'react';
import { Accordion, Header } from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import ControleCoherenceItem from './ControleCoherenceItem';
import FamilleControleCoherence from '../../../enums/familleControleCoherence';
import type TypeBudget from '../../../enums/typeBudget';
import './ControleCoherenceList.less';
import type BaseControleCoherence from '../../../classes/Outils/ControleCoherence/BaseControleCoherence';

interface ControleCoherenceListProps {
  controlesList: BaseControleCoherence[][];
  typeBudget: TypeBudget;
}

const ControleCoherenceList = ({ controlesList, typeBudget }: ControleCoherenceListProps) => {
  const [accordionsActive, setAccordionsActive] = React.useState({
    [FamilleControleCoherence.EQUILIBRE_BUDGETAIRE]: true,
    [FamilleControleCoherence.OPERATIONS_ORDRE]: true,
    [FamilleControleCoherence.AUTRES]: true,
  });

  const handleAccordionsActive = (famille: FamilleControleCoherence) => {
    setAccordionsActive((old) => ({
      ...old,
      [famille]: !old[famille],
    }));
  };

  return (
    controlesList.map((controles) => {
      const { famille } = controles[0];
      const active = accordionsActive[famille];

      return (
        <Accordion className="accordionControleCoherence" key={famille}>
          <Accordion.Title active={active} onClick={() => handleAccordionsActive(famille)}>
            <Icon
              color="#004a99"
              iconSet="Lucide"
              name={accordionsActive[famille] ? 'ChevronDown' : 'ChevronRight'}
              strokeWidth={3}
            />
            <Header size="small">{controles[0].getFamille()}</Header>
          </Accordion.Title>
          <Accordion.Content active={active}>
            {controles.map((controle) => <ControleCoherenceItem controle={controle} typeBudget={typeBudget} />)}
          </Accordion.Content>
        </Accordion>
      );
    })
  );
};

export default ControleCoherenceList;
