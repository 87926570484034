import React from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { Table } from 'rsuite-table';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { toast } from 'react-toastify';
import type Simulation from '../../../interfaces/simulation';
import ROW_HEIGHT from '../../../../FeuilleSaisie/constants/tableProps';
import { renderTreeToggle } from '../../../../../utils/treeView';
import type { TreeRow } from '../../../../FeuilleSaisie/interfaces/treeRow';
import { upsertMontants } from '../../../services/montantSimulation';

export interface ImportData {
  children?: ImportData[];
  identifiantImputation?: number;
  key: string;
  libelle: string;
  libelleDetail?: string;
  montant: number;
  montantImputationComptable: number;
  montantProposition: number;
  prop?: number;
}

interface ImportModalProps {
  simulation: Simulation;
  data?: ImportData[];
  loading?: boolean;
  onClose: () => void;
  onValidate: () => void;
}

enum ModeCalcul {
  SUBSTITUER = 0,
  CUMULER = 1,
}

const ImportTable = ({
  simulation,
  onClose,
  onValidate,
  loading,
  data,
}: ImportModalProps) => {
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [modeCalcul, setModeCalcul] = React.useState<ModeCalcul>(ModeCalcul.SUBSTITUER);

  const handleToggleModeCalcul = () => {
    setModeCalcul(modeCalcul === ModeCalcul.SUBSTITUER ? ModeCalcul.CUMULER : ModeCalcul.SUBSTITUER);
  };

  if (loading) {
    return (
      <div className="import">
        <Dimmer active inverted>
          <Loader size="massive" />
        </Dimmer>
      </div>
    );
  }

  const renderLibelleRow = (data) => (
    <div>
      <div>{data.libelle}</div>
      <div>{data.libelleDetail}</div>
    </div>
  );

  const renderPropositionAppplique = (rowData : ImportData) => {
    const besoinEstime = (rowData?.montant ?? 0) - (rowData?.montantImputationComptable ?? 0);
    if (modeCalcul === ModeCalcul.SUBSTITUER) {
      return formatMontant(besoinEstime);
    }

    return formatMontant(besoinEstime + (rowData?.montantProposition ?? 0));
  };

  const handleSave = async () => {
    const montants = [];

    data.forEach((row) => {
      row?.children?.forEach((imputation) => {
        const besoinEstime = (imputation?.montant ?? 0) - (imputation?.montantImputationComptable ?? 0);
        montants.push({
          identifiantImputation: imputation.identifiantImputation,
          // eslint-disable-next-line
          prop: modeCalcul === ModeCalcul.SUBSTITUER ? besoinEstime : besoinEstime + (imputation?.montantProposition ?? 0),
        });
      });
    });

    try {
      setLoadingSave(true);
      await upsertMontants(simulation?.identifiant, montants);
      onValidate?.();
    } catch (e) {
      toast.error('Erreur lors de la sauvegarde des propositions');
      onClose?.();
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <div className="import">
        <div className="importEmpruntChoice">
          <Button.Group>
            <Button positive={modeCalcul === ModeCalcul.SUBSTITUER} onClick={handleToggleModeCalcul}>
              Substituer les besoins estimés aux propositions déjà saisies
            </Button>
            <Button.Or text="ou" />
            <Button positive={modeCalcul === ModeCalcul.CUMULER} onClick={handleToggleModeCalcul}>
              Cumuler les besoins estimés aux propositions déjà saisies
            </Button>
          </Button.Group>
        </div>
        <Table
          className="importTable"
          data={data}
          hover={false}
          isTree
          locale={{ emptyMessage: 'Aucune donnée', loading: 'Chargement' }}
          rowKey="key"
          rowClassName="imputationTableRow"
          rowHeight={ROW_HEIGHT}
          virtualized
          wordWrap={false}
          headerHeight={60}
          autoHeight
          renderTreeToggle={(
            expandButton,
            rowData,
            isExpanded,
          ) => renderTreeToggle(rowData as unknown as TreeRow, isExpanded, '')}
        >
          {({ Cell, Column, HeaderCell }) => (
            <>
              <Column
                flexGrow={4}
                fullText
                verticalAlign="middle"
              >
                <HeaderCell>
                  {`Ligne budgétaire ${simulation?.exercice?.anneeExercice}`}
                </HeaderCell>
                <Cell dataKey="libelle">
                  {(rowData) => renderLibelleRow(rowData)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Echéance annuelle
                  <br />
                  (A)
                </HeaderCell>
                <Cell dataKey="montant">
                  {(rowData) => formatMontant(rowData?.montant)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Crédit déjà ouvert
                  <br />
                  (B)
                </HeaderCell>
                <Cell dataKey="montantImputationComptable">
                  {(rowData) => formatMontant(rowData?.montantImputationComptable)}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Besoin estimé
                  <br />
                  (C = A - B)
                </HeaderCell>
                <Cell dataKey="besoinEstime">
                  {(rowData) => formatMontant((rowData?.montant ?? 0) - (rowData?.montantImputationComptable ?? 0))}
                </Cell>
              </Column>

              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Proposition déjà saisie
                  <br />
                  (D)
                </HeaderCell>
                <Cell dataKey="montantProposition">
                  {(rowData) => formatMontant(rowData?.montantProposition)}
                </Cell>
              </Column>
              <Column
                flexGrow={2}
                fullText
                verticalAlign="middle"
                align="right"
              >
                <HeaderCell>
                  Proposition à appliquer
                  <br />
                  {modeCalcul === ModeCalcul.SUBSTITUER ? '(E = C)' : '(E = C + D)'}
                </HeaderCell>
                <Cell dataKey="montantPropositionApplique">
                  {(rowData) => renderPropositionAppplique(rowData)}
                </Cell>
              </Column>
            </>
          )}
        </Table>
      </div>

      <Modal.Actions className="importActions">
        <Button
          content="Appliquer ces propositions relatives aux emprunts"
          positive
          onClick={handleSave}
          loading={loadingSave}
        />
      </Modal.Actions>
    </>
  );
};

export default ImportTable;
