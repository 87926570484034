import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../../interfaces/montantControleCoherence';
import FamilleControleCoherence from '../../../enums/familleControleCoherence';
import StatutControleCoherence from '../../../enums/statutControleCoherence';
import TypeBudget from '../../../enums/typeBudget';

class EquilibreOperationOrdrePatrimoniale extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.OPERATIONS_ORDRE;
  }

  getExplicatif(typeBudget: TypeBudget): string {
    const depenseInvestissement041 = formatMontant(this.montants.depenseInvestissement041, 2, true);
    const recetteInvestissement041 = formatMontant(this.montants.recetteInvestissement041, 2, true);

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 041 (${depenseInvestissement041}) diffère des recettes d'investissement réalisées au 041 (${recetteInvestissement041}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 041 (${depenseInvestissement041}) diffère des recettes d'investissement inscrites au 041 (${recetteInvestissement041}).`;
      case StatutControleCoherence.OK:
        if (typeBudget === TypeBudget.COMPTE_ADMINISTRATIF) {
          return `Le total des dépenses d'investissement réalisées au chapitre 041 est égal aux recettes d'investissement réalisées au 041 (${depenseInvestissement041}).`;
        }
        return `Le total des prévisions budgétaires inscrites en dépenses d'investissement au chapitre 041 est égal aux recettes d'investissement inscrites au 041 (${depenseInvestissement041}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Déséquilibre des opérations d’ordre patrimoniales';
      case StatutControleCoherence.OK:
        return 'Équilibre des opérations d’ordre patrimoniales';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseInvestissement041, recetteInvestissement041 } = this.montants;

    if (depenseInvestissement041 === recetteInvestissement041) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default EquilibreOperationOrdrePatrimoniale;
