import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../../interfaces/montantControleCoherence';
import FamilleControleCoherence from '../../../enums/familleControleCoherence';
import StatutControleCoherence from '../../../enums/statutControleCoherence';

class OperationCession extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.AUTRES;
  }

  getExplicatif(): string {
    const {
      depenseFonctionnementOrdre6761,
      depenseInvestissementOrdre192,
      recetteFonctionnementReelle775,
      recetteFonctionnementOrdre7761,
      recetteInvestissementOrdre192,
    } = this.montants;
    let explicatifs = "Pour mémoire, les prévisions s'inscrivent au 024 alors qu'il a été recensé :\n";

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        if (depenseFonctionnementOrdre6761 !== 0) {
          explicatifs += `${formatMontant(depenseFonctionnementOrdre6761, 2, true)} sur le compte de dépenses 6761\n`;
        }
        if (depenseInvestissementOrdre192 !== 0) {
          explicatifs += `${formatMontant(depenseInvestissementOrdre192, 2, true)} sur le compte de dépenses 192\n`;
        }
        if (recetteFonctionnementReelle775 !== 0) {
          explicatifs += `${formatMontant(recetteFonctionnementReelle775, 2, true)} sur le compte de recettes 775\n`;
        }
        if (recetteFonctionnementOrdre7761 !== 0) {
          explicatifs += `${formatMontant(recetteFonctionnementOrdre7761, 2, true)} sur le compte de recettes 7761\n`;
        }
        if (recetteInvestissementOrdre192 !== 0) {
          explicatifs += `${formatMontant(recetteInvestissementOrdre192, 2, true)} sur le compte de recettes 192\n`;
        }

        return explicatifs;
      case StatutControleCoherence.OK:
        return "Pour mémoire, les prévisions s'inscrivent au 024.";
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return "Prévisions budgétaires sur les comptes d'exécution des cessions d'immobilisation.";
      case StatutControleCoherence.OK:
        return "Absence de prévisions budgétaires sur les comptes d'exécution des cessions d'immobilisation";
      default:
        return '';
    }
  }

  getStatut(): number {
    const {
      depenseFonctionnementOrdre6761,
      depenseInvestissementOrdre192,
      recetteFonctionnementReelle775,
      recetteFonctionnementOrdre7761,
      recetteInvestissementOrdre192,
    } = this.montants;

    if (depenseFonctionnementOrdre6761 === 0 && depenseInvestissementOrdre192 === 0 && recetteFonctionnementReelle775 === 0 && recetteFonctionnementOrdre7761 === 0 && recetteInvestissementOrdre192 === 0) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default OperationCession;
