import React from 'react';
import {
  Card,
  Grid,
  Header,
  type JVSIcons,
} from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import StatutControleCoherence from '../../../enums/statutControleCoherence';
import type TypeBudget from '../../../enums/typeBudget';
import './ControleCoherenceItem.less';
import type BaseControleCoherence from '../../../classes/Outils/ControleCoherence/BaseControleCoherence';

interface ControleCoherenceItemProps {
  controle: BaseControleCoherence;
  typeBudget: TypeBudget;
}

const renderIcon = (statut: StatutControleCoherence) => {
  let name = '';
  let className = 'controleCoherenceIconContainer';

  switch (statut) {
    case StatutControleCoherence.ERROR:
      className += ' iconError';
      name = 'TriangleAlert';
      break;
    case StatutControleCoherence.WARNING:
      className += ' iconWarning';
      name = 'BellRing';
      break;
    case StatutControleCoherence.OK:
      className += ' iconOk';
      name = 'CircleCheckBig';
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      <Icon
        color="white"
        iconSet="Lucide"
        name={name as JVSIcons}
        size={20}
      />
    </div>
  );
};

const ControleCoherenceItem = ({ controle, typeBudget }: ControleCoherenceItemProps) => (
  <Card className="controleCoherenceItem" fluid>
    <Card.Content>
      <Grid>
        <Grid.Row>
          <Grid.Column width={1}>
            {renderIcon(controle.statut)}
          </Grid.Column>
          <Grid.Column width={15}>
            <Card.Header>
              <Header size="small">{controle.getLibelle()}</Header>
            </Card.Header>
            <Card.Description content={controle.getExplicatif(typeBudget)} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card.Content>
  </Card>
);

export default ControleCoherenceItem;
