import React from 'react';
import {
  Button,
  Dimmer,
  Loader,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { isNil } from 'lodash';
import OutilTable from '../OutilTable';
import type { OutilTableRowProps } from '../OutilTableRow';
import type Simulation from '../../../interfaces/simulation';
import { getRepriseRestesARealiser, repriseRestesARealiser } from '../../../services/simulation';

interface ResteARealiserProps {
  onValidate: (data?: unknown) => void;
  simulation: Simulation,
}

const ResteARealiser = ({
  simulation,
  onValidate,
}: ResteARealiserProps) => {
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);
  const [data, setData] = React.useState<OutilTableRowProps[] >([]);

  const handleFetchRestesARealiser = async () => {
    try {
      setLoadingData(false);
      const rar = await getRepriseRestesARealiser(simulation?.identifiant);

      const rarDepenseInvestissement = rar?.find((r) => r.imp_sens === 'D' && r.cha_section === 'I');
      const rarDepenseFonctionnement = rar?.find((r) => r.imp_sens === 'D' && r.cha_section === 'F');
      const rarRecetteInvestissement = rar?.find((r) => r.imp_sens === 'R' && r.cha_section === 'I');
      const rarRecetteFonctionnement = rar?.find((r) => r.imp_sens === 'R' && r.cha_section === 'F');

      const newData = [
        {
          positive: false,
          solde: rarDepenseInvestissement?.montant,
          title: "Restes à réaliser en dépense d'investissement",
        },
        {
          positive: true,
          solde: rarRecetteInvestissement?.montant,
          title: "Restes à réaliser en recette d'investissement",
        },
      ];

      if (!isNil(rarDepenseFonctionnement) || !isNil(rarRecetteFonctionnement)) {
        newData.push({
          positive: false,
          solde: rarDepenseFonctionnement?.montant,
          title: 'Restes à réaliser en dépense de fonctionnement',
        });

        newData.push({
          positive: true,
          solde: rarRecetteFonctionnement?.montant,
          title: 'Restes à réaliser en recette de fonctionnement',
        });
      }

      setData(newData);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des restes à réaliser'));
    }
  };

  const handleValidate = async () => {
    try {
      setLoadingValidate(true);
      await repriseRestesARealiser(simulation?.identifiant);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la recopie des restes à réaliser'));
    } finally {
      setLoadingValidate(false);
    }
  };

  React.useEffect(() => {
    handleFetchRestesARealiser();
  }, []);

  return loadingData ? (
    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>
  ) : (
    <>
      <OutilTable data={data} />
      <div>
        <Button
          content="Reprendre les restes à réaliser"
          data-testid="confirmButton"
          disabled={loadingValidate}
          floated="right"
          loading={loadingValidate}
          onClick={handleValidate}
          positive
        />
      </div>
    </>
  );
};

export default ResteARealiser;
