import React from 'react';
import type Simulation from '../../interfaces/simulation';
import ImportExportExcelModal from '../../components/Outils/ImportExportExcel/ImportExportExcelModal';
import BaseOutil from './BaseOutil';

class ImportExportExcel extends BaseOutil {
  constructor() {
    super();
    this.code = 'IMP_EXC';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ArrowDownUp',
    };
  }

  title = 'Importer / exporter un classeur bureautique';

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <ImportExportExcelModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default ImportExportExcel;
