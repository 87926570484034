import TypeBudget from '../enums/typeBudget';
import type Simulation from '../interfaces/simulation';

const getPeriodeContentBudgetText = (simulation: Simulation): string => {
  switch (simulation?.budget?.type) {
    case TypeBudget.BUDGET_PRIMITIF:
      return 'Le budget primitif';
    case TypeBudget.BUDGET_SUPPLEMENTAIRE:
      return 'Le budget supplémentaire';
    case TypeBudget.COMPTE_ADMINISTRATIF:
      return 'Le compte administratif';
    case TypeBudget.DECISION_MODIFICATIVE:
      return 'La décision modificative';
    default:
      return '';
  }
};

export default getPeriodeContentBudgetText;
