import React from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Modal } from '@jvs-group/jvs-mairistem-composants';
import type { InputData } from '@jvs-group/jvs-mairistem-comptabilite';
import { Date, getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type ParametresSimulation from '../interfaces/parametresSimulation';
import type Simulation from '../interfaces/simulation';
import { updateSimulation } from '../services/simulation';

interface SimulationParametresModalProps {
  onClose: () => void;
  onSave: (data: ParametresSimulation) => void;
  open: boolean;
  simulation: Simulation;
}

const SimulationParametresModal = ({
  onClose,
  onSave,
  open,
  simulation,
}: SimulationParametresModalProps) => {
  const [data, setData] = React.useState<ParametresSimulation>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChangeDate = (value: string, name: string) => setData((old) => ({ ...old, [name]: value }));

  const handleChangeInput = (e, { name, value }: InputData) => setData((old) => ({ ...old, [name]: value }));

  const handleMount = () => {
    setData({
      dateConvocation: simulation?.dateConvocation,
      dateSeance: simulation?.dateSeance,
      lieuSeance: simulation?.lieuSeance,
      nombreElu: simulation?.nombreElu,
      nombrePresent: simulation?.nombrePresent,
      signataire: simulation?.signataire,
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const newData = await updateSimulation(simulation.identifiant, data);
      onSave?.({
        dateConvocation: newData?.dateConvocation,
        dateSeance: newData?.dateSeance,
        lieuSeance: newData?.lieuSeance,
        nombreElu: newData?.nombreElu,
        nombrePresent: newData?.nombrePresent,
        signataire: newData?.signataire,
      });
      toast.success('Paramètres sauvegardés avec succès');
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la sauvegarde des paramètres'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      data-testid="simulationParametreModal"
      onClose={onClose}
      onMount={handleMount}
      open={open}
    >
      <Modal.Header content="Paramètres de l'élaboration du budget" />
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Date}
              fluid
              label="Date de convocation"
              name="dateConvocation"
              onChange={handleChangeDate}
              value={data?.dateConvocation}
            />
            <Form.Field
              control={Date}
              fluid
              label="Date de séance"
              name="dateSeance"
              onChange={handleChangeDate}
              value={data?.dateSeance}
            />
            <Form.Input
              fluid
              label="Lieu de séance"
              name="lieuSeance"
              onChange={handleChangeInput}
              value={data?.lieuSeance}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Nombre d'élus"
              name="nombreElu"
              onChange={handleChangeInput}
              type="number"
              value={data?.nombreElu}
              width={4}
            />
            <Form.Input
              label="Nombre de présents"
              name="nombrePresent"
              onChange={handleChangeInput}
              type="number"
              value={data?.nombrePresent}
              width={4}
            />
            <Form.Input
              label="Signataire"
              name="signataire"
              onChange={handleChangeInput}
              value={data?.signataire}
              width={8}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Sauvegarder"
          data-testid="saveButton"
          loading={loading}
          onClick={handleSave}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SimulationParametresModal;
