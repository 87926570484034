import React from 'react';
import Panel from './Panel';
import EpargneBrute from '../../components/Panels/EpargneBrute';

class EpargneBrutePanel extends Panel {
  constructor() {
    super();
    this.code = 'EPAR_BRU';
    this.icon = {
      iconSet: 'Lucide',
      name: 'PiggyBank',
    };
    this.libelle = 'Epargne brute';
    this.size = 4;
    // eslint-disable-next-line max-len
    this.tooltip = "Capacité d'autofinancement des opérations d'investissement, a minima le remboursement du capital de la dette.";
  }

  getComponent = function getComponent(
    data: any,
  ): React.ReactElement {
    return (
      <EpargneBrute
        data={data}
        tooltip={this.tooltip}
      />
    );
  };
}

export default EpargneBrutePanel;
