import React from 'react';
import { Modal } from '@jvs-group/jvs-mairistem-composants';
import { isNil } from 'lodash';
import RecopieReportMenu from './RecopieReportMenu';
import type OutilModalProps from '../../../interfaces/outilModalProps';

const RecopieReportModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const handleClose = () => onClose?.();

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={open}
    >
      <Modal.Header content={`Reprendre les résultats reportés et restes à réaliser de ${
        !isNil(simulation?.exercice?.anneeExercice)
          ? (simulation.exercice.anneeExercice - 1) : ''}`}
      />
      <Modal.Content>
        <RecopieReportMenu onValidate={onValidate} simulation={simulation} />
      </Modal.Content>
    </Modal>
  );
};

export default RecopieReportModal;
