import React from 'react';
import { Card, Grid, Header } from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import SimulationParametresModal from './SimulationParametresModal';
import TypeBudget from '../enums/typeBudget';
import TypePeriode from '../enums/typePeriode';
import type Simulation from '../interfaces/simulation';
import Outil from './Outils/Outil';
import type UserRole from '../../../constants/userRole';
import type ParametresSimulation from '../interfaces/parametresSimulation';
import './SimulationAction.less';
import PreviousPeriode from '../classes/Outils/PreviousPeriode';
import NextPeriode from '../classes/Outils/NextPeriode';

type BaseAction = PreviousPeriode | NextPeriode;

interface SimulationActionProps {
  onChangePeriode: (typePeriode: TypePeriode) => void;
  onRefresh: () => void;
  onSave: (data: ParametresSimulation) => void;
  simulation: Simulation;
  userRole: UserRole;
}

const SimulationAction = ({
  onChangePeriode,
  onRefresh,
  onSave,
  simulation,
  userRole,
}: SimulationActionProps) => {
  const [openActionModal, setOpenActionModal] = React.useState<boolean>(false);
  const [openParametresModal, setOpenModalParametres] = React.useState<boolean>(false);
  const [selectedOutil, setSelectedOutil] = React.useState<BaseAction>();

  const outils = [new PreviousPeriode(simulation), new NextPeriode(simulation)];

  const handleClickOutil = (outil: BaseAction) => {
    setSelectedOutil(outil);
    setOpenActionModal(true);
  };

  const handleClickParametres = () => {
    setOpenModalParametres(true);
  };

  const handleCloseActionModal = () => {
    setOpenActionModal(false);
  };

  const handleCloseParametresModal = () => {
    setOpenModalParametres(false);
  };

  const handleSaveParametresModal = (data: ParametresSimulation) => {
    onSave?.(data);
    setOpenModalParametres(false);
  };

  const handleValidate = (shouldRefresh: boolean) => {
    if (shouldRefresh) {
      onRefresh?.();
    } else {
      let newTypePeriode: TypePeriode;

      if (simulation?.transfertCpta) {
        newTypePeriode = TypePeriode.VOTE;
      } else {
        switch (simulation?.typePeriode) {
          case TypePeriode.DEMANDE:
            newTypePeriode = TypePeriode.PROPOSE;
            break;
          case TypePeriode.PROPOSE:
            newTypePeriode = TypePeriode.DEMANDE;
            break;
          case TypePeriode.VOTE:
            newTypePeriode = TypePeriode.PROPOSE;
            break;
          default:
            break;
        }
      }

      onChangePeriode?.(newTypePeriode);
    }
    setOpenActionModal(false);
  };

  const getPeriodeText = () => {
    let text = '';

    if (simulation?.transfertCpta) {
      switch (simulation?.budget?.type) {
        case TypeBudget.BUDGET_PRIMITIF:
          return 'BUDGET PRIMITIF RENDU EXECUTOIRE';
        case TypeBudget.BUDGET_SUPPLEMENTAIRE:
          return 'BUDGET SUPPLEMENTAIRE RENDUE EXECUTOIRE';
        case TypeBudget.COMPTE_ADMINISTRATIF:
          return 'RESTES A REALISER VALIDES';
        case TypeBudget.DECISION_MODIFICATIVE:
          return `DM ${simulation.budget.dm.num} RENDUE EXECUTOIRE`;
        default:
          return '';
      }
    }

    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        text = 'RECENSEMENT DES DEMANDES POUR';
        switch (simulation?.budget?.type) {
          case TypeBudget.BUDGET_PRIMITIF:
            return `${text} LE BUDGET PRIMITIF`;
          case TypeBudget.BUDGET_SUPPLEMENTAIRE:
            return `${text} LE BUDGET SUPPLEMENTAIRE`;
          case TypeBudget.DECISION_MODIFICATIVE:
            return `${text} LA DM N°${simulation.budget.dm.num}`;
          default:
            break;
        }
        break;
      case TypePeriode.PROPOSE:
        text = 'FORMULATION DES PROPOSITIONS';
        switch (simulation?.budget?.type) {
          case TypeBudget.BUDGET_PRIMITIF:
            return `${text} DU BUDGET PRIMITIF`;
          case TypeBudget.BUDGET_SUPPLEMENTAIRE:
            return `${text} DU BUDGET SUPPLEMENTAIRE`;
          case TypeBudget.DECISION_MODIFICATIVE:
            return `${text} DE LA DM ${simulation.budget.dm.num}`;
          default:
            break;
        }
        break;
      case TypePeriode.VOTE:
        text = 'VOTE ';
        switch (simulation?.budget?.type) {
          case TypeBudget.BUDGET_PRIMITIF:
            text += 'DU BUDGET PRIMITIF';
            break;
          case TypeBudget.BUDGET_SUPPLEMENTAIRE:
            text += 'DU BUDGET SUPPLEMENTAIRE';
            break;
          case TypeBudget.DECISION_MODIFICATIVE:
            text += `DE LA DM ${simulation.budget.dm.num}`;
            break;
          default:
            break;
        }

        return `${text} PAR L'ASSEMBLÉE`;
      case TypePeriode.CA_CFU:
        return 'SAISIE DES RESTES A REALISER';
      default:
        break;
    }

    return '';
  };

  const renderSimulationText = (): React.JSX.Element => {
    const entiteLibelle = simulation?.exercice?.entite?.entite?.libelle;

    return (
      <>
        {entiteLibelle}
        <br />
        {`EXERCICE ${simulation?.exercice?.anneeExercice ?? ''}`}
        <br />
        <span className="periodeText">{getPeriodeText()}</span>
      </>
    );
  };

  return (
    <>
      <Card className="simulationInformations" fluid>
        <Card.Content>
          <Card.Header as={Header} size="tiny">
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>{renderSimulationText()}</Grid.Column>
                <Grid.Column width={2}>
                  <Icon
                    cursor="pointer"
                    iconSet="Lucide"
                    name="Settings"
                    onClick={handleClickParametres}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Card.Description>
            <Grid padded>
              <Grid.Row className="nowrap">
                {outils?.map((outil) => outil?.isEnabled(userRole, simulation) && (
                <Grid.Column
                  data-testid={`outil${outil?.code}`}
                  onClick={() => handleClickOutil(outil)}
                  width={outils.length === 1 ? 16 : 8}
                >
                  <Outil icon={outil?.icon} title={outil?.title} />
                </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </Card.Description>
        </Card.Content>
      </Card>
      {selectedOutil?.renderModal(simulation, handleCloseActionModal, handleValidate, openActionModal)}
      <SimulationParametresModal
        onClose={handleCloseParametresModal}
        onSave={handleSaveParametresModal}
        open={openParametresModal}
        simulation={simulation}
      />
    </>
  );
};

export default SimulationAction;
