import React from 'react';
import { Tab } from '@jvs-group/jvs-mairistem-composants';
import ResteARealiser from './ResteARealiser';
import ResultatReporte from './ResultatReporte';
import type OutilModalProps from '../../../interfaces/outilModalProps';
import './RecopieReportMenu.less';

const RecopieReportMenu = ({
  onValidate,
  simulation,
}: Pick<OutilModalProps, 'onValidate' | 'simulation'>) => {
  const PANES = [
    {
      menuItem: 'Résultats reportés',
      render: () => (
        <Tab.Pane>
          <ResultatReporte onValidate={onValidate} simulation={simulation} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Restes à réaliser',
      render: () => (
        <Tab.Pane>
          <ResteARealiser onValidate={onValidate} simulation={simulation} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Tab
      className="recopieReportMenu"
      menu={{
        'data-testid': 'recopieReportMenu',
        pointing: true,
        secondary: true,
        vertical: true,
      }}
      panes={PANES}
    />
  );
};

export default RecopieReportMenu;
