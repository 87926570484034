import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import BaseControleCoherence from './BaseControleCoherence';
import type MontantControleCoherence from '../../../interfaces/montantControleCoherence';
import FamilleControleCoherence from '../../../enums/familleControleCoherence';
import StatutControleCoherence from '../../../enums/statutControleCoherence';

class PlafondDepenseImprevuInvestissement extends BaseControleCoherence {
  constructor(montants: MontantControleCoherence) {
    super(montants);
    this.famille = FamilleControleCoherence.AUTRES;
  }

  getExplicatif(): string {
    const depenseInvestissement020 = formatMontant(this.montants.depenseInvestissement020, 2, true);
    const { depenseInvestissementReelle } = this.montants;

    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return `Les crédits inscrits au 020 (${depenseInvestissement020}) sont strictement supérieur aux 7,5% des dépenses réelles de investissement hors restes à réaliser (${depenseInvestissementReelle} x 7,5% = ${formatMontant(depenseInvestissementReelle * 0.075, 2, true)}).`;
      case StatutControleCoherence.OK:
        return `Les crédits inscrits au 020 (${depenseInvestissement020}) sont inférieurs aux 7,5% des dépenses réelles d'investissement hors restes à réaliser (${depenseInvestissementReelle} x 7,5% = ${formatMontant(depenseInvestissementReelle * 0.075, 2, true)}).`;
      default:
        return '';
    }
  }

  getLibelle(): string {
    switch (this.statut) {
      case StatutControleCoherence.ERROR:
        return 'Non-respect du plafond des dépenses imprévues en investissement';
      case StatutControleCoherence.OK:
        return 'Respect du plafond des dépenses imprévues en investissement';
      default:
        return '';
    }
  }

  getStatut(): number {
    const { depenseInvestissement020, depenseInvestissementReelle } = this.montants;

    // DI_022 <= 7,5% de depenseInvestissementReelle
    if (depenseInvestissement020 <= (depenseInvestissementReelle * 0.075)) {
      return StatutControleCoherence.OK;
    }

    return StatutControleCoherence.ERROR;
  }
}

export default PlafondDepenseImprevuInvestissement;
