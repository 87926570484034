import React from 'react';
import BaseOutil from './BaseOutil';
import CalculRARModal from '../../components/Outils/CalculRARModal';
import type Simulation from '../../interfaces/simulation';
import type UserRole from '../../../../constants/userRole';
import TypeBudget from '../../enums/typeBudget';

class CalculRAR extends BaseOutil {
  constructor() {
    super();
    this.code = 'CALC_RAR';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ArrowBigRightDash',
    };
  }

  title = 'Calculer les Restes à réaliser';

  isEnabled(userRole: UserRole, simulation: Simulation) {
    return super.isEnabled(userRole, simulation)
      && !simulation?.transfertCpta
      && simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF;
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <CalculRARModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default CalculRAR;
