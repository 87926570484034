import React from 'react';

import {
  Combobox,
  Form,
  Label,
} from '@jvs-group/jvs-mairistem-composants';
import type { DropdownOption } from '@jvs-group/jvs-mairistem-liste';

import TYPE_MONTANT_REGLE_CALCUL from '../../../constants/typeMontantRegleCalcul';
import {
  TypeCalculRegleCalcul,
  TypeCalculRegleCalculText,
} from '../../../enums/typeCalculRegleCalcul';

import type RegleCalculModalErrors
  from '../../../../Parametres/components/RegleCalcul/interfaces/RegleCalculModalErrors';
import type LigneRegleCalcul from '../../../interfaces/ligneRegleCalcul';

import './CalculPropositionModalPopupContent.less';
import getTypeMontantText from '../../../utils/regleCalcul';

interface CalculPropositionModalPopupContentProps {
  anneeExercice?: number;
  disabled?: boolean;
  errors?: RegleCalculModalErrors;
  lignesRegleCalcul: LigneRegleCalcul[];
  onChangeTypeCalcul?: (e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number }) => void;
  onChangeTypeMontant?: (e: React.MouseEvent<HTMLSelectElement>,
    { minus, value }: { minus: boolean, value: number[]}) => void;
  typeCalcul: TypeCalculRegleCalcul;
}

const getTypeCalculName = (
  typeCalcul: TypeCalculRegleCalcul,
): string => TypeCalculRegleCalculText[typeCalcul];

const getTypeMontantRegleCalculOptions = (anneeExercice: number): DropdownOption[] => Object.values(
  TYPE_MONTANT_REGLE_CALCUL,
).reduce((
  lignes: any,
  value,
) => [
  ...lignes,
  {
    'data-testid': `typeMontant${value}`,
    key: value,
    text: getTypeMontantText(value, anneeExercice),
    value,
  },
], []);

export const TYPE_CALCUL_REGLE_CALCUL_OPTIONS = [
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.ADDITION}`,
    key: TypeCalculRegleCalcul.ADDITION,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.ADDITION],
    value: TypeCalculRegleCalcul.ADDITION,
  },
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.SOUSTRACTION}`,
    key: TypeCalculRegleCalcul.SOUSTRACTION,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.SOUSTRACTION],
    value: TypeCalculRegleCalcul.SOUSTRACTION,
  },
  {
    'data-testid': `typeCalcul${TypeCalculRegleCalcul.MOYENNE}`,
    key: TypeCalculRegleCalcul.MOYENNE,
    text: TypeCalculRegleCalculText[TypeCalculRegleCalcul.MOYENNE],
    value: TypeCalculRegleCalcul.MOYENNE,
  },
] as const;

const CalculPropositionModalPopupContent = ({
  anneeExercice,
  disabled = false,
  errors,
  lignesRegleCalcul,
  onChangeTypeCalcul,
  onChangeTypeMontant,
  typeCalcul,
}: CalculPropositionModalPopupContentProps) => {
  // Permet de séparer les valeurs et les options des champs typesMontant
  const typeMontantOptionsAndValues = React.useMemo(() => {
    // Partie Valeurs
    const leftValues = [];
    const rightValues = [];

    lignesRegleCalcul?.forEach((ligne) => {
      if (ligne.minus) {
        rightValues.push(ligne.typeMontant);
      } else {
        leftValues.push(ligne.typeMontant);
      }
    });

    // Partie Options
    const options = getTypeMontantRegleCalculOptions(anneeExercice);

    return {
      left: {
        options: options.filter((option) => !rightValues.includes(option.value)),
        values: leftValues,
      },
      right: {
        options: options.filter((option) => !leftValues.includes(option.value)),
        values: rightValues,
      },
    };
  }, [anneeExercice, lignesRegleCalcul]);

  return (
    <Form>
      {
        disabled ? (
          <Form.Input
            data-testid="regleCalculActionInput"
            label="Action"
            readOnly
            value={getTypeCalculName(typeCalcul)}
          />
        ) : (
          <Form.Field
            control={Combobox}
            data-testid="regleCalculActionCombobox"
            label="Action"
            menuPortalTarget={document.body}
            name="typeCalcul"
            onChange={onChangeTypeCalcul}
            options={TYPE_CALCUL_REGLE_CALCUL_OPTIONS}
            selection
            value={typeCalcul}
          />
        )
      }
      <Form.Group>
        <Form.Field
          control={Combobox}
          data-testid="regleCalculLeftMontantReferenceCombobox"
          disabled={disabled}
          label="Montants de référence"
          menuPortalTarget={document.body}
          minus={false}
          multiple
          name="lignes"
          onChange={onChangeTypeMontant}
          options={typeMontantOptionsAndValues.left.options}
          required={!disabled}
          selection
          value={typeMontantOptionsAndValues.left.values}
          width={typeCalcul === TypeCalculRegleCalcul.SOUSTRACTION ? 8 : 16}
        />
        {
          typeCalcul === TypeCalculRegleCalcul.SOUSTRACTION && (
            <>
              <Form.Button
                className="minusButton"
                compact
                disabled
                fluid
                icon="minus"
                label=" "
              />
              <Form.Field
                control={Combobox}
                data-testid="regleCalculRightMontantReferenceCombobox"
                disabled={disabled}
                label=" "
                menuPortalTarget={document.body}
                minus
                multiple
                name="lignes"
                onChange={onChangeTypeMontant}
                options={typeMontantOptionsAndValues.right.options}
                selection
                value={typeMontantOptionsAndValues.right.values}
                width={8}
              />
            </>
          )
      }
      </Form.Group>
      { (errors?.leftLignes || errors?.rightLignes) && (
        <Form.Group>
          { errors.leftLignes && (
            <Form.Field
              color="red"
              content="Vous devez sélectionner au moins un montant"
              control={Label}
              pointing
              prompt
              width={typeCalcul === TypeCalculRegleCalcul.SOUSTRACTION ? 8 : 16}
            />
          )}
          { errors.rightLignes && <Form.Field width={errors.leftLignes ? 1 : 9} /> }
          { errors.rightLignes && (
            <Form.Field
              color="red"
              content="Vous devez sélectionner au moins un montant"
              control={Label}
              pointing
              prompt
              width={8}
            />
          )}
        </Form.Group>
      )}
    </Form>
  );
};

export default CalculPropositionModalPopupContent;
