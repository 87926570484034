import type { IconJVSProps } from '@jvs-group/jvs-mairistem-composants';
import UserRole from '../../../../constants/userRole';
import type Simulation from '../../interfaces/simulation';

class BaseOutil {
  code: string;

  icon: IconJVSProps;

  title: string = '';

  isEnabled(_userRole: UserRole, _simulation: Simulation) {
    return _userRole > UserRole.GESTIONNAIRE;
  }

  renderModal(
    _simulation: Simulation,
    _onClose: () => void,
    _onValidate: () => void,
    _open: boolean,
  ) {
    return null;
  }
}

export default BaseOutil;
