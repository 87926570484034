import React from 'react';
import { Grid } from '@jvs-group/jvs-mairistem-composants';
import { clamp } from 'lodash';
import { PieChart } from '@jvs-group/jvs-mairistem-finances-utils';
import type PanelProps from '../../interfaces/panelProps';

const SLICE_CHARGE_PERSONNEL = 'CHGPER';
const SLICE_DEPENSE_FONC = 'DEPFONC';

const CHART_LABEL = {
  [SLICE_CHARGE_PERSONNEL]: 'Charges Personnel',
  [SLICE_DEPENSE_FONC]: 'Dépenses fonctionnement',
};

const CHART_COLORS = {
  [SLICE_CHARGE_PERSONNEL]: '#ED7D31',
  [SLICE_DEPENSE_FONC]: '#5B9BD5',
};

const ChargePersonnel = ({ data }: PanelProps) => {
  const montantChargePersonnel = data?.montantChargePersonnel ?? 0;
  const depenseFonctionnement = data?.depenseReelFonctionnement ?? 0;
  const pourcentagePersonnel = clamp(
    (depenseFonctionnement > 0 ? (montantChargePersonnel * 100) / depenseFonctionnement : 0),
    0,
    100,
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} className="bold indicateurTitle">
          Ratio frais de personnel
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          {pourcentagePersonnel.toFixed(2)}
          {' '}
          %
        </Grid.Column>
        <Grid.Column width={8}>
          <PieChart
            id="personel"
            data={[
              {
                type: SLICE_CHARGE_PERSONNEL,
                percentage: pourcentagePersonnel.toFixed(2),
              },
              {
                type: SLICE_DEPENSE_FONC,
                percentage: clamp((100 - pourcentagePersonnel), 0, 100).toFixed(2),
              },
            ]}
            colors={CHART_COLORS}
            labels={CHART_LABEL}
            size={{ height: 50, width: 50 }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ChargePersonnel;
