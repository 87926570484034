/* eslint-disable max-len */
import React from 'react';
import {
  Button, Form, Modal, Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { differenceBy } from 'lodash';
import type RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import CalculPropositionModalPopupContent from '../../../../Simulation/components/Outils/CalculProposition/CalculPropositionModalPopupContent';
import CalculPropositionModalOptions from '../../../../Simulation/components/Outils/CalculProposition/CalculPropositionModalOptions';
import RegleCalculModalErrors from '../interfaces/RegleCalculModalErrors';
import { TypeCalculRegleCalcul } from '../../../../Simulation/enums/typeCalculRegleCalcul';
import TypeArrondiRegleCalcul from '../../../../Simulation/enums/typeArrondiRegleCalcul';

interface RegleCalculModalProps {
  onClose: () => void;
  onValidate: (regleCalcul: RegleCalcul) => void;
  open: boolean;
  regleCalcul?: RegleCalcul;
  loading: boolean;
}

const DEFAULT_REGLE_CALCUL: RegleCalcul = {
  defaut: false,
  deletedLignes: [],
  forfaitaire: 0,
  identifiant: 0,
  libelle: '',
  lignes: [],
  pourcentage: 0,
  typeArrondi: TypeArrondiRegleCalcul.AUCUN,
  typeCalcul: TypeCalculRegleCalcul.ADDITION,
};

export type RegleCalculModalActionsProps = Omit<RegleCalculModalProps, 'action'>;

const RegleCalculModal = ({
  onClose,
  onValidate,
  open,
  regleCalcul = DEFAULT_REGLE_CALCUL,
  loading,
}: RegleCalculModalProps) => {
  const [errors, setErrors] = React.useState<RegleCalculModalErrors>({
    leftLignes: false,
    libelle: false,
    rightLignes: false,
  });
  const [editedRegleCalcul, setEditedRegleCalcul] = React.useState<RegleCalcul>(regleCalcul);

  const isFormError = (): boolean => {
    const isLeftLignesError = !editedRegleCalcul.lignes.filter((ligne) => !ligne.minus).length;
    const isLibelleError = !editedRegleCalcul.libelle;
    const isRightLignesError = !editedRegleCalcul.lignes.filter((ligne) => ligne.minus).length
      && editedRegleCalcul.typeCalcul === TypeCalculRegleCalcul.SOUSTRACTION;

    setErrors({
      leftLignes: isLeftLignesError,
      libelle: isLibelleError,
      rightLignes: isRightLignesError,
    });

    return isLeftLignesError || isLibelleError || isRightLignesError;
  };

  const handleValidate = () => {
    if (isFormError()) return;

    onValidate(editedRegleCalcul);
  };

  const handleChangeEditedRegle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    { name, value }: HTMLInputElement | HTMLSelectElement,
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleChangeTypeCalcul = (
    e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number },
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
      lignes: [],
      deletedLignes: regleCalcul.lignes.map((regle) => regle.identifiant),
    }));
  };

  const handleChangeTypeMontant = (
    e: React.MouseEvent<HTMLSelectElement>,
    { minus, value }: { minus: boolean, value: number[]},
  ) => {
    const errorName = minus ? 'rightLignes' : 'leftLignes';

    setErrors((old) => ({
      ...old,
      [errorName]: (value.length ? false : old[errorName]),
    }));

    const allTypesMontant = editedRegleCalcul.lignes.map((ligne) => ligne.typeMontant);
    const newLignes = [
      // Je récupère les lignes que d'un côté.
      ...editedRegleCalcul.lignes.filter((ligne) => ligne.minus !== minus),
      // Je boucle sur ma liste de valeur
      ...value.map((typeMontant) => {
        // et si le typeMontant se trouve déjà dans mes lignes.
        if (allTypesMontant.includes(typeMontant)) {
          // J'ajoute celui des lignes
          return editedRegleCalcul.lignes.find((ligne) => ligne.typeMontant === typeMontant);
        }
        // Sinon, je l'ajoute
        return ({
          minus,
          typeMontant,
        });
      }),
    ];

    setEditedRegleCalcul((old) => ({
      ...old,
      lignes: newLignes,
      deletedLignes: [
        ...old.deletedLignes,
        ...differenceBy(
          old.lignes,
          newLignes,
          (ligne) => ligne.typeMontant,
        )
          .filter((ligne) => ligne?.identifiant)
          .map((ligne) => ligne.identifiant),
      ],
    }));
  };

  const handleChangeMajorationMinoration = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [currentTarget.name]: value,
    }));
  };

  const handleChangeTypeArrondi = (
    e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number },
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleMount = () => setEditedRegleCalcul(regleCalcul);
  const handleClose = () => {
    onClose();
    if (regleCalcul) {
      setEditedRegleCalcul(undefined);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      onMount={handleMount}
      open={open}
    >
      <Modal.Header content={regleCalcul?.identifiant ? `Modification de la règle de calcul: ${regleCalcul.libelle}` : 'Création de règle de calcul'} />
      <Modal.Content>
        <Form>
          <Form.Input
            input={{ 'data-testid': 'regleCalculLibelleInput' }}
            label="Libellé de la règle de calcul"
            name="libelle"
            error={errors?.libelle && 'Le champ est obligatoire'}
            onChange={handleChangeEditedRegle}
            required
            value={editedRegleCalcul?.libelle}
          />
          Formulation
          <Segment>
            <CalculPropositionModalPopupContent
              errors={errors}
              lignesRegleCalcul={editedRegleCalcul?.lignes}
              onChangeTypeCalcul={handleChangeTypeCalcul}
              onChangeTypeMontant={handleChangeTypeMontant}
              typeCalcul={editedRegleCalcul?.typeCalcul}
            />
          </Segment>
          Options par défaut
          <Segment>
            <CalculPropositionModalOptions
              majorationMinorationValues={{
                forfaitaire: editedRegleCalcul?.forfaitaire,
                pourcentage: editedRegleCalcul?.pourcentage,
              }}
              onChangeInput={handleChangeMajorationMinoration}
              onChangeTypeArrondi={handleChangeTypeArrondi}
              typeArrondi={editedRegleCalcul?.typeArrondi}
            />
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          icon="x"
          onClick={onClose}
          size="tiny"
        />
        <Button
          content={editedRegleCalcul?.identifiant ? 'Modifier' : 'Créer'}
          data-testid="editButton"
          loading={loading}
          onClick={handleValidate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RegleCalculModal;
