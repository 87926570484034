import React from 'react';
import BaseOutil from './BaseOutil';
import TypePeriode from '../../enums/typePeriode';
import PreviousPeriodeModal from '../../components/Outils/Periode/PreviousPeriodeModal';
import type Simulation from '../../interfaces/simulation';
import type UserRole from '../../../../constants/userRole';
import RecensementDemande from '../../enums/recensementDemande';
import TypeBudget from '../../enums/typeBudget';

const getPeriodeTitle = (simulation: Simulation): string => {
  if (simulation?.transfertCpta) {
    return 'Revenir en vote par l’assemblée';
  }

  switch (simulation?.typePeriode) {
    case TypePeriode.PROPOSE:
      return 'Revenir en recensement des demandes';
    case TypePeriode.VOTE:
      return 'Revenir en formulation des propositions';
    default:
      return '';
  }
};

class PreviousPeriode extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'PRE_PER';
    this.icon = { iconSet: 'Lucide', name: 'ArrowLeftFromLine' };
    this.title = getPeriodeTitle(simulation);
  }

  isEnabled(userRole: UserRole, simulation: Simulation): boolean {
    const isEnabled = super.isEnabled(userRole, simulation);

    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        // Il n'y a pas de periode avant le demande
        return false;
      case TypePeriode.PROPOSE:
        // Peut-on aller en periode de recensement des demandes ?
        switch (simulation?.exercice?.recensementDemande) {
          case RecensementDemande.BP:
            return isEnabled && simulation?.budget?.type === TypeBudget.BUDGET_PRIMITIF;
          case RecensementDemande.BP_BS:
            return isEnabled && [
              TypeBudget.BUDGET_PRIMITIF, TypeBudget.BUDGET_SUPPLEMENTAIRE,
            ].includes(simulation?.budget?.type);
          case RecensementDemande.BP_BS_DM:
            return isEnabled && [
              TypeBudget.BUDGET_PRIMITIF, TypeBudget.BUDGET_SUPPLEMENTAIRE, TypeBudget.DECISION_MODIFICATIVE,
            ].includes(simulation?.budget?.type);
          case RecensementDemande.JAMAIS:
          default:
            return false;
        }
      case TypePeriode.VOTE:
        return isEnabled;
      case TypePeriode.CA_CFU:
        return isEnabled && simulation?.transfertCpta;
      default:
        return false;
    }
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (shouldRefresh: boolean) => void,
    open: boolean,
  ) {
    return (
      <PreviousPeriodeModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
        title={this.title}
      />
    );
  }
}

export default PreviousPeriode;
