import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';

import type ImputationSimulation from '../../../interfaces/imputationSimulation';

const getRAR = (identifiant: number) => request.get<any, TemplateRequestGet<ImputationSimulation>>(
  `/api/finances/simulation/imputationSimulation?identifiantSimulation=${identifiant}&rar=not(0)&perPage=1`,
);

export default getRAR;
