import React from 'react';
import { Tab } from '@jvs-group/jvs-mairistem-composants';
import TypePeriode from '../../../enums/typePeriode';
import ExportExcel from './ExportExcel';
import ImportExcel from './ImportExcel';
import type OutilModalProps from '../../../interfaces/outilModalProps';
import './ImportExportExcelMenu.less';

const ImportExportExcelMenu = ({
  onValidate,
  simulation,
}: Pick<OutilModalProps, 'onValidate' | 'simulation'>) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const PANES = [
    {
      menuItem: 'Exporter les données',
      render: () => (
        <Tab.Pane>
          <ExportExcel simulation={simulation} />
        </Tab.Pane>
      ),
    },
    simulation?.typePeriode === TypePeriode.PROPOSE && {
      menuItem: 'Importer des données',
      render: () => (
        <Tab.Pane loading={loading}>
          <ImportExcel
            loading={loading}
            onValidate={onValidate}
            setLoading={setLoading}
            simulation={simulation}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Tab
      className="importExportExcelMenu"
      menu={{
        'data-testid': 'importExportExcelMenu',
        pointing: true,
        secondary: true,
        vertical: true,
      }}
      panes={PANES}
    />
  );
};

export default ImportExportExcelMenu;
