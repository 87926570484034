import React from 'react';
import { Button, Modal } from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import TypePeriode from '../../../enums/typePeriode';
import type PeriodeModalProps from '../../../interfaces/periodeModalProps';
import type Simulation from '../../../interfaces/simulation';
import getPeriodeContentBudgetText from '../../../utils/outil';
import { annulationTransfertComptabilite, annulationVote } from '../../../services/simulation';

const renderPeriodeContent = (simulation: Simulation) => {
  if (simulation?.transfertCpta) {
    return (
      <>
        Vous allez revenir en phase de vote par l’assemblée délibérante.
        <br />
        <br />
        {`${getPeriodeContentBudgetText(simulation)} va perdre son caractère exécutoire.`}
        <br />
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Les montants votés n'alimenteront plus les crédits disponibles pour l'exécution budgétaire.
      </>
    );
  }

  switch (simulation?.typePeriode) {
    case TypePeriode.PROPOSE:
      return 'Vous allez revenir en phase de recensement des demandes d’inscription budgétaires.';
    case TypePeriode.VOTE:
      return 'Vous allez revenir en phase de formulation des propositions par l’ordonnateur.';
    default:
      return '';
  }
};

const PreviousPeriodeModal = ({
  onClose,
  onValidate,
  open,
  simulation,
  title,
}: PeriodeModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleValidate = async () => {
    if (simulation?.typePeriode !== TypePeriode.VOTE && !simulation?.transfertCpta) {
      onValidate?.(false);
    } else {
      try {
        setLoading(true);
        if (simulation?.transfertCpta) {
          await annulationTransfertComptabilite(simulation?.identifiant);
        } else if (simulation?.typePeriode === TypePeriode.VOTE) {
          await annulationVote(simulation?.identifiant);
        }
        onValidate?.(true);
      } catch (e) {
        let errorText = '';
        if (simulation?.transfertCpta) {
          errorText = "Erreur lors de l'annulation du transfert en comptabilité";
        } else if (simulation?.typePeriode === TypePeriode.VOTE) {
          errorText = "Erreur lors de l'annulation du vote";
        }
        toast.error(getErrorMessage(e, errorText));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={open}
    >
      <Modal.Header content={title} />
      <Modal.Content>
        {renderPeriodeContent(simulation)}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Vers la période précédente"
          data-testid="confirmButton"
          disabled={loading}
          loading={loading}
          onClick={handleValidate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default PreviousPeriodeModal;
