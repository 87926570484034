import React from 'react';
import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import RegleCalculModal from '../Modal/RegleCalculModal';
import RegleCalculListRow from './RegleCalculListRow';
import {
  createRegleCalcul,
  deleteRegleCalcul,
  getReglesCalcul,
  updateRegleCalcul,
} from '../../../../../services/regleCalcul';

const RegleCalculList = () => {
  const [currentRegleCalcul, setCurrentRegleCalcul] = React.useState<RegleCalcul>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [reglesCalcul, setReglesCalcul] = React.useState<RegleCalcul[]>([]);

  const fetchReglesCalcul = async () => {
    try {
      setLoading(true);
      const { data } = await getReglesCalcul({
        all: true,
        defaut: false,
        withLignes: true,
      });
      setReglesCalcul(data.map((regleCalcul) => ({
        ...regleCalcul,
        deletedLignes: [],
      })));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Impossible de récuperer les règles de calcul'));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchReglesCalcul();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCurrentRegleCalcul(undefined);
  };

  const handleDeleteRegleCalcul = async (regleCalcul: RegleCalcul) => {
    try {
      await deleteRegleCalcul(regleCalcul.identifiant);
      setReglesCalcul((old) => old.filter((regle) => regle.identifiant !== regleCalcul.identifiant));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la suppression de la règle de calcul'));
    }
  };

  const handleDeleteClick = (regleCalcul: RegleCalcul) => {
    handleDeleteRegleCalcul(regleCalcul);
  };

  const handleEditRegleCalcul = (regleCalcul: RegleCalcul) => {
    setCurrentRegleCalcul(regleCalcul);
    setOpen(true);
  };

  const handleValidate = async (regleCalcul: RegleCalcul) => {
    handleClose();
    if (regleCalcul?.identifiant) {
      // Modification
      try {
        setLoading(true);
        updateRegleCalcul(regleCalcul);
        setReglesCalcul((old) => old.map(
          (regle) => ((regle.identifiant === regleCalcul.identifiant) ? regleCalcul : regle),
        ));
      } catch (e) {
        toast.error(getErrorMessage(e, 'Erreur lors de la modification de la règle de calcul'));
      } finally {
        setLoading(false);
      }
    } else {
      // Création
      let newRegleCalcul;
      try {
        setLoading(true);
        newRegleCalcul = await createRegleCalcul(regleCalcul);
        setReglesCalcul((old) => [
          ...old,
          newRegleCalcul,
        ]);
      } catch (e) {
        toast.error(getErrorMessage(e, 'Erreur lors de la création de la règle de calcul'));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <ListeTableContainer
        items={reglesCalcul}
        allowDeletions
        onAddWithModaleClick={() => setOpen(true)}
        onEditWithModaleClick={handleEditRegleCalcul}
        onDeleteClick={handleDeleteClick}
        additionLabel="Créer une règle de calcul"
      >
        <ListeTable loading={loading}>
          <ListeTable.Header>
            <ListeTable.Column name="libelle" width={16}>Libellé</ListeTable.Column>
          </ListeTable.Header>
          {
          // @ts-ignore
          (regleCalcul: RegleCalcul, invalidate, rowProps) => (
            <RegleCalculListRow
              regleCalcul={regleCalcul}
              rowProps={rowProps}
            />
          )
}
        </ListeTable>
      </ListeTableContainer>
      <RegleCalculModal
        onClose={handleClose}
        onValidate={handleValidate}
        open={open}
        loading={loading}
        regleCalcul={currentRegleCalcul}
      />
    </>
  );
};

export default RegleCalculList;
