import React from 'react';
import { Grid, Tooltip } from '@jvs-group/jvs-mairistem-composants';
import {
  BarSeries,
  Chart,
  ScaleType,
  Settings,
} from '@elastic/charts';
import { clamp } from 'lodash';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { formatWithSpaces } from '../../utils/panel';
import TypeBudget from '../../enums/typeBudget';
import './EpargneBrute.less';
import type PanelProps from '../../interfaces/panelProps';

const EpargneBrute = ({ data, tooltip }: PanelProps) => {
  const epargneBrute = data?.epargneBrute ?? 0;
  const recetteReelFonctionnement = data?.recetteReelFonctionnement ?? 0;
  const taux = clamp(recetteReelFonctionnement > 0 ? (epargneBrute * 100) / recetteReelFonctionnement : 0, 0, 100);
  const chartData = [
    {
      x: 'Epargne Brute N-2',
      y:
        (data?.exercicePrecedent2?.epargneBrute ?? 0),
      g: 'Valeur',
    },
    {
      x: 'Epargne Brute N-1',
      y:
        (data?.exercicePrecedent?.epargneBrute ?? 0),
      g: 'Valeur',
    },
    { x: 'Epargne Brute', y: epargneBrute, g: 'Valeur :' },
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Tooltip
            message={tooltip}
            trigger={(
              <div className="bold indicateurTitle">
                {`Epargne brute ${data?.anneeExercice}`}
              </div>
            )}
            type="information"
          />

          <br />
          <br />
          {`${data?.typeBudget === TypeBudget.COMPTE_ADMINISTRATIF ? 'Réalisations' : 'Prévisions'} :`}
          <br />
          {formatMontant(epargneBrute)}
          <br />
          <br />
          {`Taux: ${taux?.toFixed(2)}%`}
        </Grid.Column>
        <Grid.Column width={6} className="epargneChartContainer">
          <Chart>
            <Settings />
            <BarSeries
              color={['#737373', epargneBrute >= 0 ? '#2193ac' : '#a61f43']}
              data={chartData}
              id="epargneBrute"
              minBarHeight={10}
              splitSeriesAccessors={['g']}
              stackAccessors={['x']}
              tickFormat={formatWithSpaces}
              xAccessor="x"
              xScaleType={ScaleType.Linear}
              yAccessors={['y']}
              yScaleType={ScaleType.Linear}
            />
          </Chart>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EpargneBrute;
