import React from 'react';
import Panel from './Panel';
import ChargePersonnel from '../../components/Panels/ChargePersonnel';

class ChargePersonnelPanel extends Panel {
  constructor() {
    super();
    this.code = 'CHG_PER';
    this.icon = {
      iconSet: 'Lucide',
      name: 'UserRound',
    };
    this.libelle = 'Charges de personnel';
    this.size = 4;
  }

  getComponent = function getComponent(
    data: any,
  ): React.ReactElement {
    return (
      <ChargePersonnel
        data={data}
      />
    );
  };
}

export default ChargePersonnelPanel;
