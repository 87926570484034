import React from 'react';
import { Form, Segment } from '@jvs-group/jvs-mairistem-composants';
import type Simulation from '../interfaces/simulation';
import Panels from './Panels/Panels';
import TreeView from '../../FeuilleSaisie/components/TreeView';
import BoiteOutil from './BoiteOutil';
import TypePeriode from '../enums/typePeriode';
import SessionContext from '../../../components/Context/SessionContext';
import TypeBudget from '../enums/typeBudget';
import ShowOnly from '../../FeuilleSaisie/constants/showOnly';
import type Filters from '../interfaces/filters';
import SimulationAction from './SimulationAction';
import './ElaborationBudget.less';
import type ParametresSimulation from '../interfaces/parametresSimulation';
import { getSimulation } from '../services/simulation';
import type { TreeRow } from '../../FeuilleSaisie/interfaces/treeRow';
import { handleChangeMontantToSimulation } from '../utils/simulation';

interface ElaborationBudgetProps {
  identifiantSimulation: number;
}

const ElaborationBudget = ({ identifiantSimulation }: ElaborationBudgetProps) => {
  const { userRole } = React.useContext(SessionContext);
  const [filters, setFilters] = React.useState<Filters>();
  const [simulation, setSimulation] = React.useState<Simulation>(null);

  const getDefaultPeriode = (simulation : Simulation) => {
    if (simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF) {
      return TypePeriode.CA_CFU;
    }

    return simulation?.vote ? TypePeriode.VOTE : TypePeriode.PROPOSE;
  };

  const fetchSimulation = async (identifiantSimulation: number, onlyPanelData: boolean = false) => {
    const s = await getSimulation(identifiantSimulation);

    // Remise a zero des filtres
    setFilters({ showOnly: ShowOnly.TOUTES_LIGNES });

    if (onlyPanelData) {
      setSimulation((old) => ({
        ...old,
        panelData: s.panelData,
      }));
    } else {
      setSimulation({
        ...s,
        typePeriode: getDefaultPeriode(s),
      });
    }
  };

  const handleRefreshSimulation = (
    onlyPanelData: boolean = false,
  ) => fetchSimulation(Number(identifiantSimulation), onlyPanelData);

  // Récupération de l'identifiant de simulation dans l'url
  React.useEffect(() => {
    handleRefreshSimulation();
  }, []);

  const handleChangeMontant = (differencesMontants: any, imputation: TreeRow) => {
    handleChangeMontantToSimulation(differencesMontants, imputation, simulation, setSimulation);
  };

  const handleChangePeriode = async (typePeriode: TypePeriode) => {
    setSimulation((old) => ({
      ...old,
      typePeriode,
    }));
  };

  const handleChangeSimulation = (data: ParametresSimulation) => {
    setSimulation((old) => ({ ...old, ...data }));
  };

  return (
    <Segment basic secondary>
      <Form>
        <Form.Group>
          <Form.Field
            control={SimulationAction}
            onChangePeriode={handleChangePeriode}
            onRefresh={handleRefreshSimulation}
            onSave={handleChangeSimulation}
            simulation={simulation}
            userRole={userRole}
            width={4}
          />
          <Form.Field
            control={BoiteOutil}
            onRefresh={handleRefreshSimulation}
            simulation={simulation}
            userRole={userRole}
            width={12}
          />
        </Form.Group>
      </Form>

      <Panels simulation={simulation} />

      <TreeView
        filters={filters}
        onChangeMontant={handleChangeMontant}
        onRefresh={handleRefreshSimulation}
        setFilters={setFilters}
        simulation={simulation}
        userRole={userRole}
      />
    </Segment>
  );
};

export default ElaborationBudget;
